<template>
    <div class="body-box">
        <!-- 第三行数据 -->
        <div class="content-box">
            <div>
                <dv-border-box-12 v-if="false">
                    <centerLeft1 />
                </dv-border-box-12>
            </div>
            <div>
                <dv-border-box-12>
                    <centerLeft ref="centerLeft"/>
                </dv-border-box-12>
            </div>
            <!-- 中间 -->
            <div>
                <dv-border-box-10>
                    <centerSumInfo ref="centerSumInfo"/>
                </dv-border-box-10>
            </div>
            <!-- 中间 -->
            <div>
                <centerRight2 v-if="false"/>
            </div>
            <div>
                <dv-border-box-13>
                    <centerRight1 ref="centerRight1"/>
                </dv-border-box-13>
            </div>
        </div>

        <!-- 第四行数据 -->
        <div class="content-box">
            <dv-border-box-13>
                <bottomLeft ref="bottomLeft"/>
            </dv-border-box-13>
            <center ref="center"/>
            <dv-border-box-12>
                <!-- <bottomRight /> -->
                <bottomRight2 ref="bottomRight2"/>
            </dv-border-box-12>
        </div>
    </div>
</template>
<script>
    import { formatTime } from '../../utils/index.js'
    import centerLeft from "../contentsj/centerLeft";
    import centerLeft1 from "../contentsj/centerLeft1";
    import centerLeft2 from "../contentsj/centerLeft2";
    import centerRight1 from "../contentsj/centerRight1";
    import centerRight2 from "../contentsj/centerRight2";
    import center from "../contentsj/center";
    import centerSumInfo from "../contentsj/centerSumInfo";
    import bottomLeft from "../contentsj/bottomLeft";
    import bottomRight from "../contentsj/bottomRight";
	import bottomRight2 from "../contentsj/bottomRight2";
    export default {
        data () {
            return {
				deptId:'',
            };
        },
        components: {
            centerLeft,
            centerLeft1,
            centerLeft2,
            centerRight1,
            centerRight2,
            center,
            bottomLeft,
            bottomRight,
            centerSumInfo,
			bottomRight2
        },
        mounted () {

        },
        methods: {
			changeDept(deptId){
				this.deptId = deptId
				this.$refs.centerLeft.changeDept(this.deptId);
				this.$refs.centerRight1.changeDept(this.deptId);
				this.$refs.center.changeDept(this.deptId);
				this.$refs.bottomLeft.changeDept(this.deptId);
				this.$refs.bottomRight2.changeDept(this.deptId);
			}
        }
    };
</script>

<style lang="scss">
    @import './src/assets/scss/index.scss';
    .dv-border-box-10 .border-box-content{
        min-width: 10rem;
    }
</style>