<template>
  <div id="center" >
    <div class="up">
      <swiper class="mySwiper" ref="mySwiper" :options="swiperOptions" v-if="images.length>0">
		  <swiper-slide v-for="item in images">
		  	<img :src="item.url" class="swiperImage"/>
		  </swiper-slide>
          <!-- <swiper-slide>
			  <img src="https://www.swiper.com.cn/demo/images/nature-1.jpg" class="swiperImage"/>
		  </swiper-slide>
          <swiper-slide>
			  <img src="https://www.swiper.com.cn/demo/images/nature-2.jpg"  class="swiperImage"/>
		  </swiper-slide>
          <swiper-slide>
			  <img src="https://www.swiper.com.cn/demo/images/nature-3.jpg"  class="swiperImage"/>
		  </swiper-slide>
          <swiper-slide>
			  <img src="https://www.swiper.com.cn/demo/images/nature-4.jpg"  class="swiperImage"/>
		  </swiper-slide>
          <swiper-slide>
			  <img src="https://www.swiper.com.cn/demo/images/nature-5.jpg"  class="swiperImage"/>
		  </swiper-slide> -->
          <div class="swiper-pagination" slot="pagination"></div>
		  <!-- <div class="swiper-button-prev" slot="button-prev"></div> -->
		  <!-- <div class="swiper-button-next" slot="button-next"></div> -->
        </swiper>
    </div>
  </div>
</template>

<script>
import 'swiper/css/swiper.css'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import Math from '@/utils/mathUtil'
import {crudQuery} from '@/api/data'
export default {
  data () {
    return {
	  images:[],
	  swiperOptions: {
		autoplay: {
		  delay: 2500,
		  disableOnInteraction: false,//手动滑动后是否接着自动滑动，设置为false会自动滑
		},
		speed:1000,
		loop:true,
		effect: 'coverflow',
		grabCursor: true,
	    centeredSlides: true,
	    slidesPerView: 'auto',
	    coverflowEffect: {
		  rotate: 50,
		  stretch: 0,
		  depth: 100,
		  modifier: 1,
		  slideShadows: true,
	    },
		//分页的切换设置
		navigation: {
		  nextEl: '.swiper-button-next',
		  prevEl: '.swiper-button-prev',
		},
		pagination: {
		  el: '.swiper-pagination',
		  type:'bullets',
		  clickable:true
		},
		// Some Swiper option/callback...
	  }
    };
  },
  components: {
    Swiper,
    SwiperSlide
  },
 //  computed: {
	// swiper() {
	//   return this.$refs.mySwiper.$swiper
	// }
 //  },
  mounted() {
	// console.log('Current Swiper instance object', this.swiper)
	// this.swiper.slideTo(3, 1000, false)
	this.getImages()
	// setInterval(this.getSumInfo,1000*30)
  },
  methods:{
	onSwiper(swiper) {
	  console.log(swiper)
	},
	onSlideChange() {
	  console.log('slide change')
	},
	getImages(){
		let that = this
		crudQuery('api/sjCarouselInfo',{status:1}).then(res=>{
			console.log(res)
			this.images = [];
			res.content.forEach(item=>{
				this.images.push({
					url:item.avatarPath
				})
			})
		})
		// setTimeout(()=>{
		// 	for(var i=1;i<=5;i++){
		// 		that.images.push({
		// 			url:'https://www.swiper.com.cn/demo/images/nature-'+i+'.jpg'
		// 		})
		// 	}
		// },3000)
	},
	getProjectInfo(){
		this.ranking.data=[]
		crudQuery('dataV/qryDesignRate').then(res=>{
			console.log(res)
			res.forEach(item=>{
				this.ranking.data.push({
					name:item.projectName,
					value:Math.doMul(item.rate,100)
				})
			})
		})
	}
  }
};
</script>

<style lang="scss" scoped>
.swiper-container{
    --swiper-theme-color: #ff6600;
    --swiper-pagination-color: #00ff33;/* 两种都可以 */
  }	
#center {
  min-width: 6rem;
  display: flex;
  flex-direction: column;
  .up {
    padding-tpp:8px !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
	.mySwiper{
	  width: 9.65rem;
	  height: 4.95rem;
	  padding-top:10px;
	  .swiperImage{
		width: 10rem;
		height: 5.05rem;  
	  }
	}
    .item {
      border-radius: 0.0625rem;
      padding-top: 0.2rem;
      margin-top: 0.1rem;
      width: 50%;
      height: 1.075rem;
    }
  }
  .down {
    padding: 0.07rem 0.05rem;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    height: 3.1875rem;
    justify-content: space-between;
    .bg-color-black {
      border-radius: 0.0625rem;
    }
    .ranking {
      padding: 0.125rem;
      width: 100%;
    }
    .percent {
      width: 40%;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 50%;
        height: 1.5rem;
        span {
          margin-top: 0.0875rem;
          display: flex;
          justify-content: center;
        }
      }
      .water {
        width: 100%;
      }
    }
  }
}
</style>