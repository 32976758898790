<template>
  <div id="centreRight1">
    <dv-charts class="right" :option="option" v-if="flag == true"/>
  </div>
</template>

<script>
import Math from '@/utils/mathUtil'
import {crudQuery} from '@/api/data'
export default {
  props:{
	title: { type: String, required: false,default:'数据统计' },
	// ydata:{ type: Array, required: false,default:[] },
	// xdata:{ type: Array, required: false,default:[] },
  },
  data() {
    return {
	  flag:false,
	  xdata:[],
	  ydata:[],
	  legend:[],
	  option: {}
    };
  },
  components: {},
  mounted() {
	  // this.getProjectInfo();
	  // setInterval(this.getProjectInfo,1000*30)
  },
  methods: {
	  setOption(xdata,ydata,legend){
		  this.xdata = xdata
		  this.ydata = ydata
		  this.legend = legend
		  console.log(this.legend)
		  this.flag = true
		  this.option = {
			title: {
			  text: this.title,
			  style: {
				fill: '#ff5722',fontSize: 17,fontWeight: 'bold',textAlign: 'center',textBaseline: 'bottom'
			  }
			},
			legend: {
			  data: this.legend,
			  textStyle: {
			    fill: '#fff'
			  }
			},
			xAxis: {
			  name: '日期',
			  nameTextStyle: {fill: '#33cea0',fontSize: 14},
			  axisLine:{
				style: {stroke: 'white',lineWidth: 1}  
			  },
			  axisLabel:{
				style: {fill: '#33cea0',fontSize: 14,rotate: 0}
			  },
			  axisTick: {
				show: false
			  },
			  data: this.xdata
			},
			yAxis: {
			  name: '数量',
			  data: 'value',
			  nameTextStyle: {fill: '#33cea0',fontSize: 14},
			  axisLine:{
				style: {stroke: 'white',lineWidth: 1}  
			  },
			  axisLabel:{
				style: {fill: '#33cea0',fontSize: 14,rotate: 0}
			  },
			  splitLine: {
			    show: false
			  },
			},
			// color:['#ff5722'],
			series: [
			  {
				name:this.legend[0].name,
				data: this.ydata[0],
				type: 'line',
				linePoint: {
				  radius: 4
			    },
				stack: 'a',
				lineStyle: {
				  stroke: this.legend[0].color
				},
				label: {
				  show: true,
				  formatter: '{value} ',
				  style: {
					fill: this.legend[0].color,  
				    fontSize: 16
				  }
				}
			  },
			  {
				name:this.legend[1].name,
				data: this.ydata[1],
				type: 'line',
				linePoint: {
				  radius: 4
			    },
				stack: 'b',
				lineStyle: {
				  stroke: this.legend[1].color
				},
				label: {
				  show: true,
				  formatter: '{value} ',
				  style: {
					fill: this.legend[1].color,    
					fontSize: 16
				  }
				}
			  },
			  {
				name:this.legend[2].name,
				data: this.ydata[2],
				type: 'line',
				linePoint: {
				  radius: 4
			    },
				stack: 'c',
				lineStyle: {
				  stroke: this.legend[2].color
				},
				label: {
				  show: true,
				  formatter: '{value} ',
				  style: {
					fill: this.legend[2].color,    
					fontSize: 16
				  }
				}
			  },
			  {
				name:this.legend[3].name,
				data: this.ydata[3],
				type: 'line',
				linePoint: {
				  radius: 4
			    },
				lineStyle: {
				  stroke: this.legend[3].color
				},
				stack: 'd',
				label: {
				  show: true,
				  formatter: '{value} ',
				  style: {
					fill: this.legend[3].color,    
					fontSize: 16
				  }
				}
			  },
			  {
				name:this.legend[4].name,
				data: this.ydata[4],
				type: 'line',
				linePoint: {
				  radius: 4
			    },
				lineStyle: {
				  stroke: this.legend[4].color
				},
				stack: 'd',
				label: {
				  show: true,
				  formatter: '{value} ',
				  style: {
					fill: this.legend[4].color,    
					fontSize: 16
				  }
				}
			  }
			]
		  }
	  }
  }
};
</script>

<style lang="scss" scoped="scoped">
#centreRight1 {
  padding: 0.2rem;
  height: 5.125rem;
  min-width: 6.5rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 4.8125rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 0.125rem;
    overflow: hidden;
  }
}
</style>