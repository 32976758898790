<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 style="width:33.3%;height:.0625rem;" />
          <div class="d-flex jc-center">
            <dv-decoration-8 :color="['#568aea', '#000000']" style="width:2.5rem;height:.625rem;" />
            <div class="title">
              <span class="title-text">项目材料智能管理平台</span>
              <dv-decoration-6
                class="title-bototm"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
                style="width:3.125rem;height:.1rem;"
              />
            </div>
            <dv-decoration-8
              :reverse="true"
              :color="['#568aea', '#000000']"
              style="width:2.5rem;height:.625rem;"
            />
          </div>
          <dv-decoration-10 style="width:33.3%;height:.0625rem; transform: rotateY(180deg);" />
        </div>

        <!-- 第二行 -->
        <div class="d-flex jc-between px-2">
          <div class="d-flex" style="width: 40%">
            <div
              class="react-right ml-4"
              style="width: 6.25rem; text-align: left;background-color: #0f1325;"
            >
              <span class="react-before"></span>
              <span class="text" style="width: 100%;text-align: center;">
				  <el-dropdown trigger="click" @command="handleCommand">
			    <span class="el-dropdown-link">
			      {{deptName}}<i class="el-icon-arrow-down el-icon--right"></i>
			    </span>
			    <el-dropdown-menu slot="dropdown">
			      <el-dropdown-item :command="item"  v-for="item in deptArray">{{item.name}}</el-dropdown-item>
			    </el-dropdown-menu>
			  </el-dropdown>
			  </span>
            </div>
            <div class="react-right ml-3 mr-3" :class="selectFlag==1?'bg-color-blue':'defaultBg'" @click="show(1)">
              <span class="text fw-b">首页</span>
            </div>
          </div>
          <div style="width: 40%" class="d-flex">
            <div class="react-left mr-3 " :class="selectFlag==2?'bg-color-blue':'defaultBg'" @click="show(2)">
              <span class="text fw-b">生产</span>
            </div>
            <div
              class="react-left mr-4"
              style="width: 6.25rem; background-color: #0f1325; text-align: right;"
            >
			  	
              <span class="react-after">
				  
			  </span>
              <span class="text">{{dateYear}} {{dateWeek}} {{dateDay}}</span>
            </div>
          </div>
        </div>

       </div> 
	  <contentsj v-if="showFlag[0]" ref="contentsj"></contentsj>
	  <contentsjproduce v-if="showFlag[1]" ref="contentsjproduce"></contentsjproduce>
    </dv-full-screen-container>
  </div>
</template>

<script>
import { formatTime } from '../utils/index.js'
import contentsj from "./contents/contentsj";
import contentsjproduce from "./contents/contentsjproduce";
import {crudQuery} from '@/api/data'
export default {
  data () {
    return {
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
	  showFlag:[true,false],
	  selectFlag:1,
	  deptId: '',
	  deptName:'全部',
	  deptArray:[]
    };
  },
  components: {
    contentsj,
	contentsjproduce
  },
  mounted () {
	this.getProjectInfo();
    this.timeFn();
    this.cancelLoading();
  },
  methods: {
	getProjectInfo(){
		let that = this
		let deptArray = [{id:'',name:'全部'}]
		crudQuery('dataV/queryDept').then(res=>{
			console.log(res)
			res.forEach(item=>{
				deptArray.push(item)
			})
			that.deptArray = deptArray
		})
	},
	handleCommand(command) {
	  // this.$message('click on item ' + command);
	  this.deptId = command.id
	  this.deptName = command.name
	  if(this.showFlag[0] == true){
	  	this.$refs.contentsj.changeDept(this.deptId)		  
	  }else{
		this.$refs.contentsjproduce.changeDept(this.deptId)  
	  }
    },  
	show(flag){
		var showFlag = []
		for(var i=1;i<=this.showFlag.length;i++){
		  if(flag === i){
			showFlag.push(true)
		  }else{
			showFlag.push(false)
		  }
		}
	    this.showFlag = showFlag
		this.selectFlag = flag
	},
    timeFn () {
      setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss');
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd');
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000)
    },
    cancelLoading () {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }
};
</script>

<style lang="scss">
@import '../assets/scss/index.scss';
.el-dropdown{
	color:white !important;
}
</style>