<template>
  <div class="right-chart-2">
    <div class="d-flex pt-2 pl-2">
      <span style="color:#5cd9e8">
        <icon name="chart-pie"></icon>
      </span>
      <div class="d-flex">
        <span class="fs-xl text mx-2">混凝土分类统计</span>
        <dv-decoration-1 style="width:1.25rem;height:.25rem; position:relative;top:-.0375rem;" />
      </div>
    </div>

    <div class="rc1-chart-container">
      

      <dv-charts class="right" :option="option" v-if="configdata.length>0"/>
    </div>
  </div>
</template>

<script>
	import Math from '@/utils/mathUtil'
	import {crudQuery} from '@/api/data'
export default {
  name: 'RightChart2',
  data () {
    return {
		deptId:'',
		interval:'',
		configdata:[],
		option: {
			series: [
			  {
				type: 'pie',
				data: [],
				radius: ['45%', '65%'],
				insideLabel: {
				  show: false
				},
				outsideLabel: {
				  labelLineEndLength: 10,
				  formatter: '{percent}%\n{name}',
				  style: {
					fontSize: 14,
					fill: '#fff'
				  }
				}
			  }
			],
			color: ['#00baff', '#3de7c9', '#fff', '#ffc530', '#469f4b'],
        }
    }
  },
  mounted() {
  	  this.getProjectInfo();
  	  // setInterval(this.getProjectInfo,1000*30)
  },
  created() {
  	  this.interval = setInterval(this.getProjectInfo,1000*30)
  },
  beforeDestroy() {
  	  if(this.interval){
  		  clearInterval(this.interval)
  	  }
  },
  methods: {
	  changeDept(deptId){
	  	this.deptId = deptId
	  	this.getProjectInfo();
	  },
  	  getProjectInfo(){
  		this.configdata=[]
		var params={deptId:this.deptId}
		let that = this
  	  	crudQuery('dataV/qryBetonName',params).then(res=>{
  	  		console.log(res)
  	  		res.forEach((item,index)=>{
				if(index<8){
					var info = {name:item.betonName,value:Math.doAdd(item.totalCount,"0")};
					that.configdata.push(info)
				}
  				
  	  		})
			that.option.series[0].data=that.configdata
			console.log(that.configdata)
  	  	})
  	  },
	  chardata(){
		console.log(111,this.configdata)
	    return this.configdata;
	  }
  }
}
</script>

<style lang="less">
.right-chart-2 {
  min-width: 7.1rem;
  height: 100%;
  display: flex;
  flex-direction: column;

  .text {
    color: #c3cbde;
  }
  .chart-box {
    margin-top: 0.2rem;
    width: 2.125rem;
    height: 2.125rem;
    .active-ring-name {
      padding-top: 0.125rem;
    }
  }
  
  .rc1-header {
    font-size: 24px;
    font-weight: bold;
    height: 30px;
    line-height: 30px;
  }

  .rc1-chart-container {
    flex: 1;
    display: flex;
  }

  .left {
    width: 30%;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .number {
      font-size: 34px;
      color: #096dd9;
      font-weight: bold;
      margin-bottom: 30px;
    }
  }

  .right {
    flex: 1;
    padding-bottom: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
}
</style>