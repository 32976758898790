<template>
  <div>
    <Echart
      :options="options"
      id="centreLeft2Chart"
      height="6.625rem"
      width="6.625rem"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
          
              tooltip: {
                  trigger: 'item',
				  position:'right',
                  formatter: '{a} <br/>{b} : {c} ({d}%)'
              },
              
              series: [
                  {
                      name: '耗用',
                      type: 'pie',
                      radius: '55%',
                      center: ['40%', '50%'],
                      data: newData.seriesData,
                      emphasis: {
                          itemStyle: {
                              shadowBlur: 10,
                              shadowOffsetX: 0,
                              shadowColor: 'rgba(0, 0, 0, 0.5)'
                          }
                      }
                  }
              ]
        };
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
</style>