<template>
    <div class="body-box">
        <!-- 第三行数据 -->
        <div class="content-box">
			<div class="chartitem">
			    <dv-border-box-12>
			        <chartMutliTemplate ref="centerLeft6" title='数据汇总'/>
			    </dv-border-box-12>
			</div>
            <div class="chartitem">
                <dv-border-box-12>
                    <chartTemplate ref="centerLeft1" :title="titles[0]"/>
                </dv-border-box-12>
            </div>
            <!-- 中间 -->
            <div class="chartitem">
                <dv-border-box-12>
                    <chartTemplate ref="centerLeft2" :title="titles[1]" />
                </dv-border-box-12>
            </div>
            
        </div>

        <!-- 第四行数据 -->
        <div class="content-box">
			<div class="chartitem">
			    <dv-border-box-12>
			        <chartTemplate ref="centerLeft3" :title="titles[2]" />
			    </dv-border-box-12>
			</div>
            <div class="chartitem">
                <dv-border-box-12>
                    <chartTemplate ref="centerLeft4" :title="titles[3]" />
                </dv-border-box-12>
            </div>
            <div class="chartitem">
                <dv-border-box-12>
                    <chartTemplate ref="centerLeft5" :title="titles[4]"/>
                </dv-border-box-12>
            </div>
			
        </div>
    </div>
</template>
<script>
    import { formatTime } from '../../utils/index.js'
    import chartTemplate from "../sjproduce/chartTemplate";
	import chartMutliTemplate from "../sjproduce/chartMutliTemplate";
	import Math from '@/utils/mathUtil'
	import {crudQuery} from '@/api/data'
    export default {
        data () {
            return {
				deptId:'',
				interval:'',
				titles:['派单量','生产量','过磅量','签收量','图纸量'],
				colors:['#00baff','#ff5ca9','#3de7c9','#f5d94e','#f90d0d']
            };
        },
        components: {
            chartTemplate,
			chartMutliTemplate
        },
        mounted () {
			this.getProjectInfo();
			// setInterval(this.getProjectInfo,1000*30)
        },
		created() {
			this.interval = setInterval(this.getProjectInfo,1000*30)
		},
		beforeDestroy() {
		  if(this.interval){
			  clearInterval(this.interval)
		  }
		},
        methods: {
			changeDept(deptId){
				this.deptId = deptId
				console.log(deptId)
				this.getProjectInfo();
			},
			getProjectInfo(){
				let that = this
				var params={deptId:this.deptId}
				crudQuery('dataV/queryChart',params).then(res=>{
					that.$refs.centerLeft1.setOption(res.xdata,res.planValueList,[])
					that.$refs.centerLeft2.setOption(res.xdata,res.produceValueList,[])
					that.$refs.centerLeft3.setOption(res.xdata,res.weightValueList,[])
					that.$refs.centerLeft4.setOption(res.xdata,res.signValueList,[])
					that.$refs.centerLeft5.setOption(res.xdata,res.picList,[])
					var mutliArray = [res.planValueList,res.produceValueList,res.weightValueList,res.signValueList,res.picList]
					
					that.$refs.centerLeft6.setOption(res.xdata,mutliArray,that.getLegend())
				})
			},
			getLegend(){
				var legend=[];
				for(var i=0;i<this.titles.length;i++){
					legend.push({name:this.titles[i],color:this.colors[i]})
				}
				return legend;
			}
        }
    };
</script>

<style lang="scss" scoped="scoped">
    @import './src/assets/scss/index.scss';
    .dv-border-box-10 .border-box-content{
        min-width: 10rem;
    }
	.content-box{
		display: flex !important;
		justify-content: space-between !important;
	}
	.chartitem{
		flex: 1 1 33.33%
	}
	.body-box{
		justify-content: space-around !important;
		padding-bottom: 2rem !important;
		height: 100%;
	}
</style>