<template>
  <div>
    <Chart :cdata="cdata" v-if="cdata.category.length>0"/>
  </div>
</template>

<script>
import Chart from './chart.vue'
import Math from '@/utils/mathUtil'
import {crudQuery} from '@/api/data'
export default {
  data () {
    return {
      cdata: {
        category: [
          // '01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'
        ],
        lineData: [

        ],
        barData: [

        ],
        rateData: [
           // '100','90','80','70','120','250','130','70','20','30','40','70','100','150','80','70','60','90','40','85','100','150','80','55'
        ]
      },
	  deptId:'',
	  interval:'',
    };
  },
  components: {
    Chart,
  },
  mounted () {
    // this.setData();
	this.getChartInfo()
	// setInterval(this.getChartInfo,1000*30)
  },
  created() {
  	  this.interval = setInterval(this.getChartInfo,1000*30)
  },
  beforeDestroy() {
  	  if(this.interval){
  		  clearInterval(this.interval)
  	  }
  },
  methods: {
	changeDept(deptId){
		this.deptId = deptId
		this.getChartInfo();
	},  
    // 根据自己的业务情况修改
    setData () {
      for (let i = 0; i < this.cdata.barData.length; i++) {
        let rate = this.cdata.barData[i] / this.cdata.lineData[i];
        this.cdata.rateData.push(rate.toFixed(2));
      }
    },
	getChartInfo(){
		let that = this
		var params={deptId:this.deptId}
		crudQuery('dataV/qrySettleCollect',params).then(res=>{
			that.cdata.category=[]
			that.cdata.barData=[]
			that.cdata.lineData=[]
			that.cdata.rateData=[]
			res.forEach(item=>{
				that.cdata.category.push(item.supName)
				that.cdata.rateData.push(item.okAmount)
				that.cdata.lineData.push(Math.doAdd(item.noAmount,item.okAmount))
			})
			// that.setData()
		})
	}
  }
};
</script>

<style lang="scss" scoped>
</style>