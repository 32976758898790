import request from '@/utils/request'
import qs from 'qs'

export function crudQuery(url, params) {
  return request({
    url: url + '?' + qs.stringify(params, { indices: false }),
    method: 'get'
  })
}

