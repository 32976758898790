<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
import {crudQuery} from '@/api/data'
export default {
  data () {
    return {
      cdata: {
        angleAxisData: [
          // "细砂",
          // "外加剂",
          // "矿粉1",
          // "粉煤灰",
          // "细骨料",
          // "粗骨料",
          // "水泥",
          // "粗砂",
          // "清水",
          // "矿粉2 "
        ],
        seriesData: [
          
        ]
      }
    }
  },
  components: {
    Chart,
  },
  mounted() {
  	this.getSumInfo()
	setInterval(this.getSumInfo,1000*60)
  },
  methods:{
  	getSumInfo(){
		var colors =['#f54d4d','#f87544','#ffae00','#dcff00','#25d053','#01fff5','#007cff','#4245ff','#c32eff','#ff62e8']
		let that = this
  		crudQuery('dataV/qryExpend').then(res=>{
			var i=0;
			this.cdata.angleAxisData=[]
			this.cdata.seriesData=[]
			for (var key in res) {
				var item = res[key]
				this.cdata.angleAxisData.push(key)
				this.cdata.seriesData.push({
					name:key,
					value: item.expendNum,
					itemStyle: {
					  normal: {
					    color: colors[i % colors.length]
					  }
					}
				})
				if(i>6){
					break;
				}
				i = i+1
			}
  		})
  	}
  }
}
</script>

<style lang="scss" scoped>
</style>