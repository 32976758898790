<template>
  <div id="centreRight1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-line"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">车辆调度监控</span>
        </div>
      </div>
      <div class="d-flex jc-center body-box">
        <dv-scroll-board v-if="config.data.length>0" :config="config" style="width:6.0rem;height:4.0rem" />
      </div>
    </div>
  </div>
</template>

<script>
import Math from '@/utils/mathUtil'
import {crudQuery} from '@/api/data'
export default {
  data() {
    return {
      config: {
        header: ["车牌号", "车辆状态", "发车时间"],
        data: [
          // ["陕A12341", "<span  class='colorGrass'>已进场</span>", "1号站"],
          // ["陕B22342", "<span  class='colorRed'>已离场</span>", "1号站"],
          // ["陕G22443", "<span  class='colorGrass'>已进场</span>", "1号站"],
          // ["陕C52344", "<span  class='colorGrass'>已进场</span>", "1号站"],
          // ["陕A72345", "<span  class='colorGrass'>已进场</span>", "1号站"],
          // ["陕C12346", "<span  class='colorGrass'>已进场</span>", "1号站"],
          // ["陕D62347", "<span  class='colorGrass'>已进场</span>", "1号站"],
          // ["陕A13348", "<span  class='colorRed'>已离场</span>", "1号站"],
          // ["陕C12349", "<span  class='colorRed'>已离场</span>", "1号站"],
          // ["陕D12340", "<span  class='colorGrass'>已进场</span>", "1号站"]
        ],
        rowNum: 7, //表格行数
        headerHeight: 35,
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        index: true,
        columnWidth: [50,120,120],
		waitTime:1000,
        align: ["center"],
		deptId:'',
		interval:''
      }
    };
  },
  components: {},
  mounted() {
	  this.getProjectInfo();
	  // setInterval(this.getProjectInfo,1000*30)
  },
  created() {
  	  this.interval = setInterval(this.getProjectInfo,1000*30)
  },
  beforeDestroy() {
  	  if(this.interval){
  		  clearInterval(this.interval)
  	  }
  },
  methods: {
	  changeDept(deptId){
	  	this.deptId = deptId
	  	this.getProjectInfo();
	  },
	  getProjectInfo(){
		this.config.data=[]
		var params={deptId:this.deptId}
	  	crudQuery('dataV/qryCarInOut',params).then(res=>{
	  		console.log(res)
	  		res.forEach(item=>{
				if(item.isInOut ==='已签收'){
					var info = [item.carNo, "<span  class='colorRed'>"+item.isInOut+"</span>", item.postion];
					this.config.data.push(info)
				}else{
					var info = [item.carNo, "<span  class='colorGrass'>"+item.isInOut+"</span>", item.postion]
					this.config.data.push(info)
				}
	  		})
	  	})
	  }
  }
};
</script>

<style lang="scss" scoped="scoped">
#centreRight1 {
  padding: 0.2rem;
  height: 5.125rem;
  min-width: 7.1rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 4.8125rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 0.125rem;
    overflow: hidden;
  }
}
</style>