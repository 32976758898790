import axios from 'axios'
// import router from '@/router/routers'
// import { Notification } from 'element-ui'
// import store from '../store'
// import { getToken } from '@/utils/auth'
// import Config from '@/settings'
// import Cookies from 'js-cookie'

// 创建axios实例
const service = axios.create({
  // baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/', // api 的 base_url
  baseURL:'https://xmclapi.dsibc.cn/',
  timeout: 1200000 // 请求超时时间,默认2分钟
})

// request拦截器
service.interceptors.request.use(
  config => {
    // if (getToken()) {
    //   config.headers['Authorization'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    // }
    config.headers['Content-Type'] = 'application/json'
    // console.log('请求拦截器',config);
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    const code = response.status
    if (code < 200 || code > 300) {
      // Notification.error({
      //   title: response.message
      // })
      return Promise.reject('error')
    } else {
      // console.log('响应拦截器',response);
      return response.data
    }
  },
  error => {
    let code = 0
    try {
      code = error.response.data.status
    } catch (e) {
      if (error.toString().indexOf('Error: timeout') !== -1) {
        // Notification.error({
        //   title: '网络请求超时',
        //   duration: 5000
        // })
        return Promise.reject(error)
      }
    }
    console.log(errorMsg)
    return Promise.reject(error)
  }
)
export default service
