<template>
  <div id="center">
    <div class="up">
      <div class="bg-color-black item" v-for="item in titleItem" :key="item.title" v-if="titleItem.length>0">
        <p class="ml-3 colorBlue fw-b" style="text-align: center;">{{item.title}}</p>
        <div>
          <dv-digital-flop :config="item.number" style="width:100%;height:.625rem;fontSize:10" />
        </div>
      </div>
    </div>
    <div class="down">
      <div class="ranking bg-color-black">
        <span style="color:#5cd9e8">
          <icon name="align-left"></icon>
        </span>
        <span class="fs-xl text mx-2 mb-1">项目完成进度</span>
        <dv-scroll-ranking-board v-if="ranking.data.length>0" :config="ranking" style="height:3.25rem" />
      </div>
    </div>
  </div>
</template>

<script>
import CenterChart from "@/components/echart/center/centerChartRate";
import Math from '@/utils/mathUtil'
import {isNullString} from '@/utils/index'
import {crudQuery} from '@/api/data'
export default {
  data () {
    return {
	  supCount:'',
	  contractCount:'',
	  pdCount:'',
	  expendCount:'',
	  sendRecordCount:'',
	  settleAmount:'',
	  sumFlag:false,
	  deptId:'',
	  interval:'',
	  interval2:'',
      titleItem: [
        
      ],
      ranking: {
        data: [
          
        ],
        carousel: "single",
        unit: "%",
		valueFormatter: ({value})=>{
			console.warn(arguments)
			console.warn(value)
			return value
		},
      },
      water: {
        data: [24, 45],
        shape: "roundRect",
        formatter: "{value}%",
        waveNum: 3
      },
      // 通过率和达标率的组件复用数据
      rate: [
        {
          id: "centerRate1",
          tips: 60,
          colorData: {
            textStyle: "#3fc0fb",
            series: {
              color: ["#00bcd44a", "transparent"],
              dataColor: {
                normal: "#03a9f4",
                shadowColor: "#97e2f5"
              }
            }
          }
        },
        {
          id: "centerRate2",
          tips: 40,
          colorData: {
            textStyle: "#67e0e3",
            series: {
              color: ["#faf3a378", "transparent"],
              dataColor: {
                normal: "#ff9800",
                shadowColor: "#fcebad"
              }
            }
          }
        }
      ]
    };
  },
  components: {
    CenterChart
    // centerChart1,
    // centerChart2
  },
  mounted() {
  	this.getSumInfo()
	this.getProjectInfo()
	// setInterval(this.getSumInfo,1000*30)
	// setInterval(this.getProjectInfo,1000*30)
  },
  created() {
  	  this.interval = setInterval(this.getProjectInfo,1000*30)
	  this.interval2 = setInterval(this.getSumInfo,1000*30)
  },
  beforeDestroy() {
  	  if(this.interval){
  		clearInterval(this.interval)
  	  }
	  if(this.interval2){
	    clearInterval(this.interval2)
	  }
  },
  methods:{
	changeDept(deptId){
		this.deptId = deptId
		this.getSumInfo()
		this.getProjectInfo();
	},  
	getSumInfo(){
		var titleItem=[]
		var params={deptId:this.deptId};
		let that = this
		crudQuery('dataV/qryCenterData',params).then(res=>{
			console.log(res)
			that.sumFlag = true
			titleItem.push({
			  title: "累计供应商数",
			  number: {
				number: [res.supCount?res.supCount:0],
				toFixed: 0,
				content: "{nt}"
			  }
			});
			titleItem.push({
			  title: "累计派单总数",
			  number: {
				number: [res.pdCount?res.pdCount:0],
				toFixed: 0,
				content: "{nt}"
			  }
			});
			titleItem.push({
			  title: "累积采购合同数",
			  number: {
				number: [res.contractCount?res.contractCount:0],
				toFixed: 0,
				content: "{nt}"
			  }
			});
			titleItem.push({
			  title: "累积发货单数",
			  number: {
				number: [res.sendRecordCount?res.sendRecordCount:0],
				toFixed: 0,
				content: "{nt}"
			  }
			});
			titleItem.push({
			  title: "累积异常数量",
			  number: {
				number: [res.expendCount?res.expendCount:0],
				toFixed: 0,
				content: "{nt}"
			  }
			});
			titleItem.push({
			  title: "累积结算总金额(元)",
			  number: {
				number: [res.settleAmount?res.settleAmount:0],
				toFixed: 2,
				content: "{nt}"
			  }
			});
			that.titleItem = titleItem
		})
	},
	getProjectInfo(){
		this.ranking.data=[]
		var params={deptId:this.deptId}
		crudQuery('dataV/qryDesignRate',params).then(res=>{
			console.log(res)
			res.forEach(item=>{
				this.ranking.data.push({
					name:item.projectName+'('+item.buildPosition+')',
					value:Math.doMul(isNullString(item.rate)?"0":item.rate ,100)
				})
			})
		})
	}
  }
};
</script>

<style lang="scss" scoped>
#center {
  min-width: 10rem;
  display: flex;
  flex-direction: column;
  margin-top:0.2rem;
  .up {
    
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      border-radius: 0.0625rem;
      padding-top: 0.2rem;
      margin-top: 0.1rem;
      width: 32%;
      height: 1.075rem;
    }
  }
  .down {
    padding: 0.2rem 0.05rem;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    height: 3.5875rem;
    justify-content: space-between;
    .bg-color-black {
      border-radius: 0.0625rem;
	  height: 3.55rem;
    }
    .ranking {
      padding: 0.125rem;
      width: 100%;
    }
    .percent {
      width: 40%;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 50%;
        height: 1.5rem;
        span {
          margin-top: 0.0875rem;
          display: flex;
          justify-content: center;
        }
      }
      .water {
        width: 100%;
      }
    }
  }
}
</style>